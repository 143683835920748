import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/seo'
import { rhythm } from 'utils/typography'
import { PostNodeT } from 'templates/blog-post/types'

// Necessary for css prop for ts
import {} from '@emotion/core'

type PropsT = {
  location: string
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
    posts: {
      edges: { node: PostNodeT }[]
    }
  }
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    posts: allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          body
          excerpt(pruneLength: 160)
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`

const BlogIndex = ({ data, location }: PropsT) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.posts.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <div key={node.fields.slug}>
            <h3
              css={{
                marginBottom: rhythm(0.25),
              }}
            >
              <Link
                css={{ boxShadow: `none` }}
                to={`biking/cc-tour/${node.fields.slug}`}
              >
                {title}
              </Link>
            </h3>
            <small>{node.frontmatter.date}</small>
            <p>{node.excerpt}</p>
          </div>
        )
      })}
    </Layout>
  )
}

export default BlogIndex
