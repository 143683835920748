import Typography from 'typography'
import Noriega from 'typography-theme-noriega'

const typography = new Typography({
  ...Noriega,
  overrideThemeStyles: {
    'a.gatsby-resp-image-link': {
      boxShadow: `none`,
    },
    a: {
      textDecoration: 'none',
    },
  },
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export const { rhythm, scale } = typography
export default typography
